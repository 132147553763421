
:root{
  /*website colors*/
    --blue-thema: #3358f4;
    --blue-thema-second: #1d8cf8;

    --green-thema: #0098f0;
    --green-thema-second: #00f2c3;

    --primary-thema: #ba54f5;
    --primary-thema-second: #ba54f5;
}
/* .sidebar[data="blue"],
.off-canvas-sidebar[data="blue"], 
.btn [data="blue"] {
  background: var(--blue-thema) !important;
  background: -webkit-linear-gradient(0deg, var(--blue-thema) 0%, var(--blue-thema-second) 100%);
  background: -o-linear-gradient(0deg, var(--blue-thema) 0%, var(--blue-thema-second) 100%);
  background: -moz-linear-gradient(0deg, var(--blue-thema) 0%, var(--blue-thema-second) 100%);
  background: linear-gradient(0deg, var(--blue-thema) 0%, var(--blue-thema-second) 100%);}
.sidebar[data="green"],
.off-canvas-sidebar[data="green"],
.btn[data="green"]  {
  background: var(--green-thema) !important;
  background: -webkit-linear-gradient(0deg, var(--green-thema) 0%, var(--green-thema-second) 100%);
  background: -o-linear-gradient(0deg, var(--green-thema) 0%, var(--green-thema-second) 100%);
  background: -moz-linear-gradient(0deg, var(--green-thema) 0%, var(--green-thema-second) 100%);
  background: linear-gradient(0deg, var(--green-thema) 0%, var(--green-thema-second) 100%); }
.sidebar[data="primary"],
.off-canvas-sidebar[data="primary"], 
.btn [data="primary"] {
  background: var(--primary-thema) !important;
  background: -webkit-linear-gradient(0deg, var(--primary-thema) 0%, var(--primary-thema-second) 100%);;
  background: -o-linear-gradient(0deg, var(--primary-thema) 0%, var(--primary-thema-second) 100%);;
  background: -moz-linear-gradient(0deg, var(--primary-thema) 0%, var(--primary-thema-second) 100%);;
  background: linear-gradient(0deg, var(--primary-thema) 0%, var(--primary-thema-second) 100%); } */

.main-panel[data="blue"] {
  border-top: 2px solid var(--blue-thema-second); }
.main-panel[data="green"] {
  border-top: 2px solid var(--green-thema-second); }
.main-panel[data="primary"] {
  border-top: 2px solid var(--primary-thema-second); }

.main-panel[data="blue"] .floating-label > input:focus,
.main-panel[data="blue"] .form-with-value:focus {
  border-color: var(--blue-thema-second) ; }
.main-panel[data="green"] .floating-label > input:focus,
.main-panel[data="green"] .form-with-value:focus {
  border-color: var(--green-thema-second) ; }
.main-panel[data="primary"] .floating-label > input:focus,
.main-panel[data="primary"] .form-with-value:focus {
  border-color: var(--primary-thema-second) ; }

.main-panel[data="blue"] .Mui-focused .MuiOutlinedInput-notchedOutline,
.main-panel[data="blue"] .Mui-focused {
    border-color: var(--blue-thema-second) ;
    color: var(--blue-thema-second) ;
    border-width: 1px;
}
.main-panel[data="green"] .Mui-focused .MuiOutlinedInput-notchedOutline,
.main-panel[data="green"] .Mui-focused {
    border-color: var(--green-thema-second) ;
    color: var(--green-thema-second) ;
    border-width: 1px;
}
.main-panel[data="primary"] .Mui-focused .MuiOutlinedInput-notchedOutline,
.main-panel[data="primary"] .Mui-focused{
    border-color: var(--primary-thema-second) ;
    color: var(--primary-thema-second) ;
    border-width: 1px;
}
.Mui-focused input::placeholder ,.Mui-focused textarea::placeholder, .Mui-focused input, .Mui-focused textarea{
  color: #000 !important;
  margin-top: 3px !important;
}
.main-panel[data="blue"] .custom-control-input:checked ~ .custom-control-label::before,
.main-panel[data="blue"] .custom-control-label::after {
  background-color: var(--blue-thema-second) ;
  background-color: -webkit-linear-gradient(0deg, var(--blue-thema) 0%, var(--blue-thema-second) 100%);
  background-color: -o-linear-gradient(0deg, var(--blue-thema) 0%, var(--blue-thema-second) 100%);
  background-color: -moz-linear-gradient(0deg, var(--blue-thema) 0%, var(--blue-thema-second) 100%);
  background-color: linear-gradient(0deg, var(--blue-thema) 0%, var(--blue-thema-second) 100%);
  border-color: var(--blue-thema);
}
.main-panel[data="green"] .custom-control-input:checked ~ .custom-control-label::before,
.main-panel[data="green"] .custom-control-label::after {
  background-color: var(--green-thema-second) ;
  background-color: -webkit-linear-gradient(0deg, var(--green-thema) 0%, var(--green-thema-second) 100%);
  background-color: -o-linear-gradient(0deg, var(--green-thema) 0%, var(--green-thema-second) 100%);
  background-color: -moz-linear-gradient(0deg, var(--green-thema) 0%, var(--green-thema-second) 100%);
  background-color: linear-gradient(0deg, var(--green-thema) 0%, var(--green-thema-second) 100%);
  border-color: var(--green-thema);
}
.main-panel[data="primary"] .custom-control-input:checked ~ .custom-control-label::before,
.main-panel[data="primary"] .custom-control-label::after {
  background-color: var(--primary-thema-second) ;
  background-color: -webkit-linear-gradient(0deg, var(--primary-thema) 0%, var(--primary-thema-second) 100%);
  background-color: -o-linear-gradient(0deg, var(--primary-thema) 0%, var(--primary-thema-second) 100%);
  background-color: -moz-linear-gradient(0deg, var(--primary-thema) 0%, var(--primary-thema-second) 100%);
  background-color: linear-gradient(0deg, var(--primary-thema) 0%, var(--primary-thema-second) 100%);
  border-color: var(--primary-thema);
}

.main-panel[data="blue"] .btn-fill{
  background: -webkit-linear-gradient(0deg, var(--blue-thema) 0%, var(--blue-thema-second) 100%) ;
  background: -o-linear-gradient(0deg, var(--blue-thema) 0%, var(--blue-thema-second) 100%) ;
  background: -moz-linear-gradient(0deg, var(--blue-thema) 0%, var(--blue-thema-second) 100%) ;
  background: linear-gradient(0deg, var(--blue-thema) 0%, var(--blue-thema-second) 100%) ;
}

.main-panel[data="green"] .btn-fill{
  background: -webkit-linear-gradient(0deg, var(--green-thema) 0%, var(--green-thema-second) 100%) ;
  background: -o-linear-gradient(0deg, var(--green-thema) 0%, var(--green-thema-second) 100%) ;
  background: -moz-linear-gradient(0deg, var(--green-thema) 0%, var(--green-thema-second) 100%) ;
  background: linear-gradient(0deg, var(--green-thema) 0%, var(--green-thema-second) 100%) ;
}

.main-panel[data="primary"] .btn-fill{
  background: -webkit-linear-gradient(0deg, var(--primary-thema) 0%, var(--primary-thema-second) 100%) ;
  background: -o-linear-gradient(0deg, var(--primary-thema) 0%, var(--primary-thema-second) 100%) ;
  background: -moz-linear-gradient(0deg, var(--primary-thema) 0%, var(--primary-thema-second) 100%) ;
  background: linear-gradient(0deg, var(--primary-thema) 0%, var(--primary-thema-second) 100%) ;
}
.detail-buttons .icon-pencil {
  color: var(--blue-thema-second);
}
.detail-buttons:hover .icon-pencil {
  color: var(--blue-thema);
}
.detail-buttons .icon-simple-remove {
  color: var(--primary-thema-second);
}
.detail-buttons:hover .icon-simple-remove {
  color: var(--primary-thema);
}

.btn {
  background: linear-gradient(0deg,#252161 0%, #423685 100%) !important;
  color: #fff !important;
  border-color: #252161 !important;
}
.btn:hover {
  background: #252161 !important;
}
.main-panel .custom-control-input:checked ~ .custom-control-label::before, .main-panel .custom-control-label::after {
  background: linear-gradient(0deg,#252161 0%, #423685 100%) !important;
  color: #fff !important;
  border-color: #252161 !important;
}
.off-canvas-sidebar, .sidebar {
  background: linear-gradient(0deg,#252161 0%, #423685 100%) !important;
}
.MuiPaper-root {
  box-shadow: none !important;
}
/* --------------------------------

Nucleo Outline Web Font - nucleoapp.com/
License - nucleoapp.com/license/
Created using IcoMoon - icomoon.io

-------------------------------- */

@font-face {
  font-family: 'Nucleo';
  src: url('../fonts/nucleo.eot');
  src: url('../fonts/nucleo.eot') format('embedded-opentype'), url('../fonts/nucleo.woff2') format('woff2'), url('../fonts/nucleo.woff') format('woff'), url('../fonts/nucleo.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}


/*------------------------
base class definition
-------------------------*/

.tim-icons {
  display: inline-block;
  font: normal normal normal 1em/1 'Nucleo';
  vertical-align: middle;
  speak: none;
  text-transform: none;
  /* Better Font Rendering */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.font-icon-detail {
  text-align: center;
  padding: 45px 0 30px;
  border: 1px solid #e44cc4;
  border-radius: .1875rem;
  margin: 15px 0;
  min-height: 168px;
}

.font-icon-detail i {
  color: #FFFFFF;
  font-size: 1.5em;
}

.font-icon-detail p {
  color: #e44cc4 !important;
  margin-top: 30px;
  padding: 0 10px;
  font-size: .7142em;
}


/*------------------------
change icon size
-------------------------*/

.tim-icons-sm {
  font-size: 0.8em;
}

.tim-icons-lg {
  font-size: 1.2em;
}


/* absolute units */

.tim-icons-16 {
  font-size: 16px;
}

.tim-icons-32 {
  font-size: 32px;
}


/*----------------------------------
add a square/circle background
-----------------------------------*/

.tim-icons-bg-square,
.tim-icons-bg-circle {
  padding: 0.35em;
}

.tim-icons-bg-circle {
  border-radius: 50%;
}


/*------------------------
list icons
-------------------------*/


/*------------------------
spinning icons
-------------------------*/

.tim-icons-is-spinning {
  -webkit-animation: tim-icons-spin 2s infinite linear;
  -moz-animation: tim-icons-spin 2s infinite linear;
  animation: tim-icons-spin 2s infinite linear;
}

@-webkit-keyframes tim-icons-spin {
  0% {
      -webkit-transform: rotate(0deg);
  }
  100% {
      -webkit-transform: rotate(360deg);
  }
}

@-moz-keyframes tim-icons-spin {
  0% {
      -moz-transform: rotate(0deg);
  }
  100% {
      -moz-transform: rotate(360deg);
  }
}

@keyframes tim-icons-spin {
  0% {
      -webkit-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -ms-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
  }
  100% {
      -webkit-transform: rotate(360deg);
      -moz-transform: rotate(360deg);
      -ms-transform: rotate(360deg);
      -o-transform: rotate(360deg);
      transform: rotate(360deg);
  }
}


/*------------------------
rotated/flipped icons
-------------------------*/


/*------------------------
icons
-------------------------*/

.icon-alert-circle-exc::before {
  content: "\ea02";
}

.icon-align-center::before {
  content: "\ea03";
}

.icon-align-left-2::before {
  content: "\ea04";
}

.icon-app::before {
  content: "\ea05";
}

.icon-atom::before {
  content: "\ea06";
}

.icon-attach-87::before {
  content: "\ea07";
}

.icon-badge::before {
  content: "\ea08";
}

.icon-bag-16::before {
  content: "\ea09";
}

.icon-bank::before {
  content: "\ea0a";
}

.icon-basket-simple::before {
  content: "\ea0b";
}

.icon-bell-55::before {
  content: "\ea0c";
}

.icon-bold::before {
  content: "\ea0d";
}

.icon-book-bookmark::before {
  content: "\ea0e";
}

.icon-bulb-63::before {
  content: "\ea0f";
}

.icon-bullet-list-67::before {
  content: "\ea10";
}

.icon-bus-front-12::before {
  content: "\ea11";
}

.icon-button-pause::before {
  content: "\ea12";
}

.icon-button-power::before {
  content: "\ea13";
}

.icon-calendar-60::before {
  content: "\ea14";
}

.icon-camera-18::before {
  content: "\ea15";
}

.icon-caps-small::before {
  content: "\ea16";
}

.icon-cart::before {
  content: "\ea17";
}

.icon-chart-bar-32::before {
  content: "\ea18";
}

.icon-chart-pie-36::before {
  content: "\ea19";
}

.icon-chat-33::before {
  content: "\ea1a";
}

.icon-check-2::before {
  content: "\ea1b";
}

.icon-cloud-download-93::before {
  content: "\ea1c";
}

.icon-cloud-upload-94::before {
  content: "\ea1d";
}

.icon-coins::before {
  content: "\ea1e";
}

.icon-compass-05::before {
  content: "\ea1f";
}

.icon-controller::before {
  content: "\ea20";
}

.icon-credit-card::before {
  content: "\ea21";
}

.icon-delivery-fast::before {
  content: "\ea22";
}

.icon-double-left::before {
  content: "\ea23";
}

.icon-double-right::before {
  content: "\ea24";
}

.icon-email-85::before {
  content: "\ea25";
}

.icon-gift-2::before {
  content: "\ea26";
}

.icon-globe-2::before {
  content: "\ea27";
}

.icon-headphones::before {
  content: "\ea28";
}

.icon-heart-2::before {
  content: "\ea29";
}

.icon-html5::before {
  content: "\ea2a";
}

.icon-image-02::before {
  content: "\ea2b";
}

.icon-istanbul::before {
  content: "\ea2c";
}

.icon-key-25::before {
  content: "\ea2d";
}

.icon-laptop::before {
  content: "\ea2e";
}

.icon-light-3::before {
  content: "\ea2f";
}

.icon-link-72::before {
  content: "\ea30";
}

.icon-lock-circle::before {
  content: "\ea31";
}

.icon-map-big::before {
  content: "\ea32";
}

.icon-minimal-down::before {
  content: "\ea33";
}

.icon-minimal-left::before {
  content: "\ea34";
}

.icon-minimal-right::before {
  content: "\ea35";
}

.icon-minimal-up::before {
  content: "\ea36";
}

.icon-mobile::before {
  content: "\ea37";
}

.icon-molecule-40::before {
  content: "\ea38";
}

.icon-money-coins::before {
  content: "\ea39";
}

.icon-notes::before {
  content: "\ea3a";
}

.icon-palette::before {
  content: "\ea3b";
}

.icon-paper::before {
  content: "\ea3c";
}

.icon-pencil::before {
  content: "\ea3d";
}

.icon-pin::before {
  content: "\ea3e";
}

.icon-planet::before {
  content: "\ea3f";
}

.icon-puzzle-10::before {
  content: "\ea40";
}

.icon-satisfied::before {
  content: "\ea41";
}

.icon-scissors::before {
  content: "\ea42";
}

.icon-send::before {
  content: "\ea43";
}

.icon-settings-gear-63::before {
  content: "\ea44";
}

.icon-settings::before {
  content: "\ea45";
}

.icon-simple-add::before {
  content: "\ea46";
}

.icon-simple-delete::before {
  content: "\ea47";
}

.icon-simple-remove::before {
  content: "\ea48";
}

.icon-single-02::before {
  content: "\ea49";
}

.icon-single-copy-04::before {
  content: "\ea4a";
}

.icon-sound-wave::before {
  content: "\ea4b";
}

.icon-spaceship::before {
  content: "\ea4c";
}

.icon-square-pin::before {
  content: "\ea4d";
}

.icon-support-17::before {
  content: "\ea4e";
}

.icon-tablet-2::before {
  content: "\ea4f";
}

.icon-tag::before {
  content: "\ea50";
}

.icon-tap-02::before {
  content: "\ea51";
}

.icon-tie-bow::before {
  content: "\ea52";
}

.icon-time-alarm::before {
  content: "\ea53";
}

.icon-trash-simple::before {
  content: "\ea54";
}

.icon-triangle-right-17::before {
  content: "\ea55";
}

.icon-trophy::before {
  content: "\ea56";
}

.icon-tv-2::before {
  content: "\ea57";
}

.icon-upload::before {
  content: "\ea58";
}

.icon-user-run::before {
  content: "\ea59";
}

.icon-vector::before {
  content: "\ea5a";
}

.icon-video-66::before {
  content: "\ea5b";
}

.icon-volume-98::before {
  content: "\ea5c";
}

.icon-wallet-43::before {
  content: "\ea5d";
}

.icon-watch-time::before {
  content: "\ea5e";
}

.icon-wifi::before {
  content: "\ea5f";
}

.icon-world::before {
  content: "\ea60";
}

.icon-zoom-split::before {
  content: "\ea61";
}

.icon-refresh-01::before {
  content: "\ea62";
}

.icon-refresh-02::before {
  content: "\ea63";
}

.icon-shape-star::before {
  content: "\ea64";
}

.icon-components::before {
  content: "\ea65";
}

.custom-switch .custom-control-label::after,
.custom-switch .custom-control-label::before {
  cursor: pointer;
}

.custom-switch .custom-control-input:checked~.custom-control-label::after {
  background: #fff !important;
}

.custom-control-input:not(:disabled):active~.custom-control-label::before {
  color: #ffffff;
  background-color: rgba(199, 205, 221, 0.2);
  border-color: rgba(34, 42, 66, 0.2);
  box-shadow: none;
}

.floating-label>input:focus {
  box-shadow: none !important;
}

.file-input-label {
  font-size: 0.75rem !important;
}

.form-control-file {
  cursor: pointer;
}

.floating-label>input,
.floating-label>input:focus {
  background: var(--dark) !important;
  border: 1px solid #4b545e;
  color: #000;
}

.file-input-example {
  border: 1px solid #4b545e;
}

.white-content .floating-label>input,
.white-content .floating-label>input:focus {
  background: #fff !important;
  border: 1px solid #ced4da;
}

.white-content .file-input-example {
  filter: brightness(1);
  border: 1px solid #ced4da;
}
.mw-35 img{
  height: 80px;
  width: auto;
}
.mw-70 {
  min-width: 70%;
}

.mw-35 {
  min-width: 35%;
}

.mw-30 {
  min-width: 30%;
}
.mw-40 {
  min-width: 30%;
}

.mw-25 {
  min-width: 25%;
}

.mw-24 {
  min-width: 24%;
}

.mw-20 {
  min-width: 20%;
}

.mw-18 {
  min-width: 18%;
}

.mw-15 {
  min-width: 15%;
}

.mw-12 {
  min-width: 12%;
}

.mw-10 {
  min-width: 10%;
}

.mw-8 {
  min-width: 8%;
}

.mw-6 {
  min-width: 6%;
}

.mw-5 {
  min-width: 5%;
}

.mw-4 {
  min-width: 4%;
}
.width-15 {
  width: 15%;
}
.width-30 {
  width: 30%;
}
.width-15 {
  width: 15%;
}
.width-10 {
  width: 10%;
}
.width-20 {
  width: 20%;
}
.width-40 {
  width: 40%;
}
.font-icon-symbols option {
  font-family: 'FontAwesome', 'sans-serif' !important;
}

.form-with-value {
  border-radius: 0.25rem;
  border: 1px solid #ced4da !important;
}

.form-with-value::placeholder {
  color: #344675 !important;
}

.logo-img {
  display: flex;
  justify-content: center;
}

.RichEditor-root {
  background: #fff;
  border: 1px solid #ddd;
  font-family: 'Georgia', serif;
  font-size: 14px;
  padding: 15px;
  width: calc(100% - 30px);
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.RichEditor-editor {
  border-top: 1px solid #ddd;
  cursor: text;
  font-size: 16px;
  margin-top: 10px;
}

.RichEditor-editor .public-DraftEditorPlaceholder-root,
.RichEditor-editor .public-DraftEditor-content {
  margin: 0 -15px -15px;
  padding: 15px;
}

.RichEditor-editor .public-DraftEditor-content {
  min-height: 100px;
}

.RichEditor-hidePlaceholder .public-DraftEditorPlaceholder-root {
  display: none;
}

.RichEditor-editor .RichEditor-blockquote {
  border-left: 5px solid #eee;
  color: #666;
  font-family: 'Hoefler Text', 'Georgia', serif;
  font-style: italic;
  margin: 16px 0;
  padding: 10px 20px;
}

.RichEditor-editor .public-DraftStyleDefault-pre {
  background-color: rgba(0, 0, 0, 0.05);
  font-family: 'Inconsolata', 'Menlo', 'Consolas', monospace;
  font-size: 16px;
  padding: 20px;
}

.RichEditor-controls {
  font-family: 'Helvetica', sans-serif;
  font-size: 14px;
  margin-bottom: 5px;
  user-select: none;
}

.RichEditor-styleButton {
  color: #999;
  cursor: pointer;
  margin-right: 16px;
  padding: 2px 0;
  display: inline-block;
}

.RichEditor-activeButton {
  color: #5890ff;
}

.MuiTextField-root label {
  margin-top: 5px;
}

.detail-buttons {
  cursor: pointer;
  font-size: 18px;
}

.css-1elwnq4-MuiPaper-root-MuiAccordion-root {
  box-shadow: none !important;
}

.embed-td {
  max-height: 150px;
  overflow: scroll;
}

.css-sh22l5-MuiButtonBase-root-MuiAccordionSummary-root {
  padding: 0 !important;
}

.table th,
.table td {
  margin-top: auto;
  margin-bottom: auto;
  border: none !important;
}

.d-grid {
  display: grid !important;
}

table tbody svg,
table tbody path {
  max-width: 30px !important;
}

.drag-list,
.drag-list li ul {
  list-style: none;
  padding-left: 0;
}

.drag-list {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.dnd-list__draggable {
  margin-bottom: 5px;
  background: #fff;
  padding: 3px;
  z-index: auto;
}

.dnd-list__in-drag {
  z-index: 999;
}

.dnd-list__draggable:focus-visible {
  border: 1px solid #ddd;
}

.drag-list .dropdown-menu {
  height: 225px;
  overflow: scroll;
  right: 35% !important;
  left: 35% !important;
}

.add-new-page .dropdown-menu {
  height: 200px;
  overflow: scroll;
}

.sidebar-icons {
  color: #000;
  opacity: 1 !important;
  text-align: center;
}

.add-new-page {
  transition: height 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.table-responsive {
  overflow: auto !important;
}

.add-new-section {
  transition: height 0.3s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  overflow: hidden;
  padding-left: 32px;
  padding-right: 32px;
}

.right-explain {
  margin-left: 1rem;
}

.answers-body {
  padding: 8px 16px;
  border: 1px solid #ddd;
  border-radius: 4px;
  margin-bottom: 8px;
}

.apply-question {
  margin: 0;
  font-weight: 600;
}

.apply-answer {
  margin: 0;
}

.user-inf {
  display: flex;
  width: 100%;
}

.user-title {
  font-weight: 600;
  min-width: 45%;
}

.apply-table .MuiAccordionSummary-content {
  margin: 0;
}

.apply-td {
  margin: 4px 8px;
}

.apply-thead tr th {
  font-size: 14px !important;
}

.apply-thead tr th:nth-child(1) {
  padding-left: 22px !important;
}

.jobadv-td td {
  padding: 24px 24px !important;
}

.adv-button {
  margin-left: 30px;
}

.addable-from {
  border: 1px solid #ddd;
  padding: 1rem 0 !important;
  border-radius: 8px;
}

.action-list-marker {
  text-align: center;
  font-weight: 600;
  font-size: 16px;
  display: flex;
  align-content: center;
  align-items: center;
}

.list-action-items {
  padding-left: 12px;
}

.job-inf {
  display: flex;
  width: 100%;
}

.job-title {
  font-weight: 600;
  min-width: 25%;
  margin-bottom: 12px;
}
.transactions-buttons {
  display: flex;
  align-items: center;
  justify-content: center;
}
.transactions-buttons a .icon-trash-simple, 
.transactions-buttons a .icon-check-2, 
.transactions-buttons a .icon-simple-remove {
  transition: .2s linear all;
  font-size: 18px;
  color: #fff;
}
.white-content .transactions-buttons a .icon-trash-simple, 
.white-content .transactions-buttons a .icon-check-2, 
.white-content .transactions-buttons a .icon-simple-remove {
  color: #000;
}
.transactions-buttons a {
  width: 40px;
  display: flex;
  justify-content: center;
}
.transactions-buttons a:hover .icon-trash-simple,
.transactions-buttons a:hover .icon-simple-remove {
  transform: scale(1.2);
  color: red;
}

.transactions-buttons a:hover .icon-check-2{
  transform: scale(1.2);
  color: #05ff05;
}

.messages-buttons {
  display: flex;
  align-items: center;
  justify-content: center;
}
.messages-buttons a .icon-trash-simple, 
.messages-buttons a .icon-check-2, 
.messages-buttons a .icon-simple-remove  {
  transition: .2s linear all;
  font-size: 18px;
  color: #fff;
}
.white-content .messages-buttons a .icon-trash-simple, 
.white-content .messages-buttons a .icon-check-2, 
.white-content .messages-buttons a .icon-simple-remove {
  color: #000;
}
.messages-buttons a {
  width: 40px;
  display: flex;
  justify-content: center;
}
.messages-buttons a:hover .icon-trash-simple,
.messages-buttons a:hover .icon-simple-remove {
  transform: scale(1.2);
  color: red;
}

.messages-buttons a:hover .icon-check-2{
  transform: scale(1.2);
  color: #05ff05;
}

.RichEditor-root {
  background: #fff;
  border: 1px solid #ddd;
  font-family: 'Georgia', serif;
  font-size: 14px;
  padding: 15px;
}

.RichEditor-editor {
  border-top: 1px solid #ddd;
  cursor: text;
  font-size: 16px;
  margin-top: 10px;
}

.RichEditor-editor .public-DraftEditorPlaceholder-root,
.RichEditor-editor .public-DraftEditor-content {
  margin: 0 -15px -15px;
  padding: 15px;
}

.RichEditor-editor .public-DraftEditor-content {
  min-height: 100px;
}

.RichEditor-hidePlaceholder .public-DraftEditorPlaceholder-root {
  display: none;
}

.RichEditor-editor .RichEditor-blockquote {
  border-left: 5px solid #eee;
  color: #666;
  font-family: 'Hoefler Text', 'Georgia', serif;
  font-style: italic;
  margin: 16px 0;
  padding: 10px 20px;
}

.RichEditor-editor .public-DraftStyleDefault-pre {
  background-color: rgba(0, 0, 0, 0.05);
  font-family: 'Inconsolata', 'Menlo', 'Consolas', monospace;
  font-size: 16px;
  padding: 20px;
}

.RichEditor-controls {
  font-family: 'Helvetica', sans-serif;
  font-size: 14px;
  margin-bottom: 5px;
  user-select: none;
}

.RichEditor-styleButton {
  color: #999;
  cursor: pointer;
  margin-right: 16px;
  padding: 2px 0;
  display: inline-block;
}
.RichEditor-activeButton {
  color: #5890ff;
}
.MuiTextField-root label {
  margin-top: 5px;
}
.detail-buttons {
  cursor: pointer;
  font-size: 18px;
  margin-right: 18px;
}
.css-1elwnq4-MuiPaper-root-MuiAccordion-root {
  box-shadow: none !important;
}
.embed-td{
  max-height: 150px;
  overflow: scroll;
}
.css-sh22l5-MuiButtonBase-root-MuiAccordionSummary-root {
  padding: 0 !important;
}
.table th, .table td {
  margin-top: auto;
  margin-bottom: auto;
  border: none !important;
}
.d-grid {
  display: grid !important;
}
table tbody svg, table tbody path{
  max-width: 30px !important;

}
.drag-list, .drag-list li ul {
  list-style: none;
  padding-left: 0;
}
.drag-list {
    -webkit-touch-callout: none;
      -webkit-user-select: none;
       -khtml-user-select: none; 
         -moz-user-select: none;
          -ms-user-select: none;
              user-select: none;
}

.dnd-list__draggable {
  margin-bottom: 5px;
  background: #1e1e2f;
  padding: 3px;
  z-index: auto;
  border-radius: 4px;
  align-items: center;
}
.dnd-list__draggable:focus-visible {
  border: 1px solid #181825;
}
.drag-list li {
  color: #ddd;
  align-items: center;
  display: grid;
  align-content: center;
}

.white-content .dnd-list__draggable {
  background: #f5f6fa;
}
.white-content .dnd-list__draggable:focus-visible {
  border: 1px solid #f7f7f7;
}
.white-content .drag-list li {
  color: #1e1e2f;
}


.dnd-list__in-drag {
  z-index: 999;
}
.drag-list .dropdown-menu {
  height: 225px;
  overflow: scroll;
  right: 35% !important;
  left: 35% !important;
}
.add-new-page .dropdown-menu {
  height: 200px;
  overflow: scroll;
}
.sidebar-icons {
  color: #000;
  opacity: 1 !important;
  text-align: center;
}
.add-new-page {
  transition: height 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.table-responsive {
  overflow: auto !important;
}
.add-new-section {
  transition: height 0.3s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  overflow: hidden;
}
.jodit_theme_default {
  max-height: 500px !important;
  overflow: scroll !important;
  min-width: 100% !important;
}
.MuiFormControl-root input{
  color : rgba(255, 255, 255, 0.80) !important;
}
.MuiFormControl-root input:focus{
  color : rgba(255, 255, 255, 1) !important;
}
.MuiFormControl-root input::placeholder{
  color : rgba(255, 255, 255, 0.7) !important;
}
.form-with-value {
  border-radius: 0.25rem;
  border: 1px solid #ced4da !important;
}
.form-with-value::placeholder {
  color: #aaaebb !important;
}
.MuiAccordion-root {
  background-color: #1e1e2f !important;
  color: rgba(255, 255, 255, 0.80) !important;
}
.white-content .MuiFormControl-root input{
  color : rgba(0, 0, 0, 0.80) !important;
}
.white-content .MuiFormControl-root input:focus{
  color : rgba(0, 0, 0, 1) !important;
}
.white-content .MuiFormControl-root input::placeholder{
  color : rgba(0, 0, 0, 0.7) !important;
}
.white-content .form-with-value {
  border-radius: 0.25rem;
  border: 1px solid #ced4da !important;
}
.white-content .form-with-value::placeholder {
  color: #344675 !important;
}
.white-content .MuiAccordion-root {
  background-color: #fff !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.t-modal-body {
  display: none;
  height: 100%;
  width: 100%;
  z-index: 9999;
  background: rgba(0, 0, 0, 0.4);
  position: fixed;
  left: 0;
  top: 0;
}
.opened.t-modal-body {
  display: grid;
}
.transaction-modal {
  position: absolute;
  width: 350px;
  height: auto;
  background: #27293d;
  z-index: 999;
  border: 2px solid #ddd;
  border-radius: 5px;
  right: 0;
  left: 0;
  margin: auto;
  top: 5%;
  color: #ddd;
  display: block;
}
.white-content .transaction-modal {
  background: #fff;
  color: #000;
}
.transaction-modal .card-header {
  font-size: 17px;
}
.mobile-adv{
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}
.desktop-adv {
  display: flex;
  align-items: center;
  overflow: hidden;
}
.mobile-adv label {
  position: absolute;
  left: 17%;
  top: 2%;
  font-size: 1rem !important;
  z-index: 10;
}
.desktop-adv label {
  position: absolute;
  left: 10%;
  top: 5%;
  font-size: 1rem !important;
  z-index: 10;
}
.ad-m-mockup {
  position: relative;
  height: auto;
  width: 100%;
  z-index: 9;
}
.ad-d-mockup {
  position: relative;
  height: auto;
  width: 100%;
  z-index: 9;
}
.ad-m-preview{
  position: absolute;
  z-index: 0;
  width: 90%;
}
.ad-d-preview{
  position: absolute;
  z-index: 0;
  width: 70%;
  inset: 0;
  margin: auto;
}
.video-input{
  height: 40px;
  border: 1px solid rgba(0, 0, 0, 0.23);
  border-radius: 4px;
  display: flex;
  align-items: center;
  padding-left: 12px;
}
.video-input:hover {
  border: 1px solid rgba(0, 0, 0, 1);
}
.video-input label {
  margin-bottom: 0;
}
.pageads-td {
  margin: 4px 8px;
}
.pageads-td td {
  padding: 1rem;
}
.language-switcher {
  display: block;
  position: relative;
  width: 30px;
  border: 1px solid #ddd;
  border-right: none;
}
.language-switcher::before {
  content: '';
  background: #ddd;
  margin: auto;
  width: 85%;
  height: 1px;
  position: absolute;
  inset: 0;
}
.language-switcher button {
  background: transparent;
  border: none;
  width: 100%;
  height: 50%;
  background: #42368536;
}
.language-switcher button.active {
  background: #fff;
}
.jodit-react-container {
  width: 100%;
  min-width: calc(100% - 30px) !important;
}
iframe {
  display: none;
}
.contest-referers {
  max-height: 260px;
  overflow-y: scroll;
}
.a-button {
  border: none;
  background-color: transparent;
  font-weight: 700;
}
.btn {
  transition: none !important;
}
.contest-modal {
  position: absolute;
  width: 550px;
  height: auto;
  background: #27293d;
  z-index: 999;
  border: 2px solid #ddd;
  border-radius: 5px;
  right: 0;
  left: 0;
  margin: auto;
  top: 5%;
  color: #ddd;
  display: block;
  max-height: 80vh;
  overflow-y: scroll;
}
.white-content .contest-modal {
  background: #fff;
  color: #000;
}
.contest-modal .card-header {
  font-size: 17px;
}